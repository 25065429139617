import Rails from "@rails/ujs"
import 'landing_page/jquery-global'
import 'landing_page/bootstrap.min'
import { ajaxForm, errorMessageFor } from "ajax_forms"
Rails.start()

$(() => {
  ajaxForm({
    form: "#new_user",
    button: "#btn-signup",
    success: res => {
      $("#step1").hide();
      $("#step2").show();
    },
    error: xhr => $("#signup-error").text(errorMessageFor(xhr)).show(),
    limited: true
  });
})
